import React from 'react';
import { styled } from 'linaria/react';
import { Intl } from '@jetshop/intl';
import { theme } from '../Theming/Theming';
import { getFontSize } from '../Theming/helpers';

const NewsletterWrapper = styled.div`
  .mnmemailaddress {
    position: absolute;
    top: -5000px;
    left: -5000px;
  }
  .form-control {
  }
  .rulemailer-subscriber-form input[type='checkbox'] {
    display: inline-block;
    width: auto;
    margin-right: 10px;
  }
  .rulemailer-email-check {
    display: none !important;
  }
`;

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  button {
    width: 29%;
    height: 46px;
    line-height: 46px;
    cursor: pointer;
    background: ${theme.button.background};
    border: 1px solid white;
    color: ${theme.button.color};
    ${getFontSize(theme.button.fontSize)};
    font-family: ${theme.button.fontFamily} !important;
    font-weight: ${theme.weights.bold};
    transition: all 0.1s ease-in;
    background: #FFF;
    color: #222;
    text-transform: uppercase;
    opacity: 1 !important;
    :hover {
    }
  }
  input {
    border: none; //1px solid ${theme.colors.buy};
    font-weight: ${theme.weights.bold};
    height: 46px;
    line-height: 46px;
    width: 70%;
    padding-left: 20px;
    background: #fff;
    color: ${theme.colors.font};
    ::placeholder {
      color: ${theme.colors.font};
    }
    &:focus {
      ::placeholder {
      }
    }
  }
`;

export const CustomNewsletter = () => {
  return (
    <NewsletterWrapper>
      <Intl>
        {/* This is necessary to use t for the placeholder string */}
        {t => (
          <div className="rulemailer-subscriber-form">
            <form
              id="rule-optin-form"
              action="https://app.rule.io/subscriber-form/subscriber"
              method="POST"
            >
              <input type="hidden" value="77242" name="tags[]" />
              <input
                type="hidden"
                name="token"
                value="d1fa45de-c8b6a34-474cb86-40203db-2c09ea8-715"
              />
              <div>
                <p>
                  <input type="checkbox" name="confirmation" required />
                  Jag bekräftar att jag vill prenumerera på Meindl Sveriges
                  nyhetsbrev och att Meindl Sverige får spara uppgifterna jag
                  angivit här.{' '}
                </p>
                <a
                  href="https://www.meindl.se/pub_docs/files/Integritetspolicy.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Integritetspolicy
                </a>
              </div>
              <label className="rulemailer-email-check">Are you machine?</label>
              <input
                type="checkbox"
                name="email_field"
                className="rulemailer-email-check"
                value="1"
                tabIndex="-1"
                autoComplete="off"
              />
              <input type="hidden" name="language" value="sv" />
              <SubmitWrapper>
                <input
                  type="email"
                  placeholder="E-post"
                  name="rule_email"
                  required="required"
                />
                <button type="submit">Skicka</button>
              </SubmitWrapper>
            </form>
          </div>
        )}
      </Intl>
    </NewsletterWrapper>
  );
};
