export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter'
    },
    blog: {
      path: process.env.REACT_APP_BLOG_PATH || '/upptack',
      id: process.env.REACT_APP_BLOG_ID || 653,
      mainLevel: 1,
      menu: true
    },
    blog2: {
      path: process.env.REACT_APP_BLOG2_PATH || '/om-meindl',
      id: process.env.REACT_APP_BLOG2_ID || 343,
      mainLevel: 1,
      menu: true
    },
    blog3: {
      path: process.env.REACT_APP_BLOG2_PATH || '/service',
      id: process.env.REACT_APP_BLOG2_ID || 771,
      mainLevel: 1,
      menu: true
    },
    brand: {
      id: false
    },
    category: {
      hide: false
    },
    product: {
      hide: false
    }
  },
  settings: {
    storeName: 'Meindl',
    siteContentId: 119
  }
};
