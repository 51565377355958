import React, { useContext } from 'react';
import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import { useInView } from 'react-intersection-observer';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const ReviewsWrapper = styled('div')``;

const TrustpilotWidget = ({ type, product }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const common = {
    id: 'trustpilot-' + type,
    className: 'trustpilot-widget',
    dataLocale: selectedChannel.language
      ? selectedChannel.language.culture
      : 'sv_SE',
    dataBusinessunitId: '5c7542a161447700016802f2',
    dataTheme: 'light',
    dataReviewLang: selectedChannel.language
      ? selectedChannel.language.culture.substr(0, 2)
      : 'sv'
  };

  let articleNumber = product && product.articleNumber;

  if (
    product &&
    product.variants &&
    product.variants.values &&
    product.variants.values.length
  ) {
    articleNumber = product.variants.values.map(variant => {
      return variant.articleNumber;
    });
  }
  switch (type) {
    case 'start-page':
      return (
        <div style={{ padding: '1rem' }}>
          <div
            id={common.id}
            className={common.className}
            data-locale={common.dataLocale}
            data-businessunit-id={common.dataBusinessunitId}
            data-style-height="500px"
            data-style-width="100%"
            data-template-id="539adbd6dec7e10e686debee"
            data-stars="1,2,3,4,5"
            data-theme={common.dataTheme}
            data-review-language={common.dataReviewLang}
          />
        </div>
      );
    case 'top-bar':
      return (
        <div
          id={common.id}
          className={common.className}
          data-locale={common.dataLocale}
          data-businessunit-id={common.dataBusinessunitId}
          data-style-height="20px"
          data-style-width="290px"
          data-template-id="5419b732fbfb950b10de65e5"
          data-sku={articleNumber}
          data-review-language={common.dataReviewLang}
          data-theme="dark"
        />
      );
    case 'product-page-mini':
      return (
        <div
          id={common.id}
          className={common.className}
          data-locale={common.dataLocale}
          data-businessunit-id={common.dataBusinessunitId}
          data-style-height="24px"
          data-style-width="188px"
          data-template-id="54d39695764ea907c0f34825"
          data-sku={articleNumber}
          data-theme={common.dataTheme}
          data-review-language={common.dataReviewLang}
        />
      );
    case 'product-page-review':
      return (
        <div
          id={common.id}
          className={common.className}
          data-locale={common.dataLocale}
          data-businessunit-id={common.dataBusinessunitId}
          data-style-height="700px"
          data-style-width="100%"
          data-template-id="5763bccae0a06d08e809ecbb"
          data-sku={articleNumber}
          data-name={product && product.name}
          data-theme={common.dataTheme}
          data-review-language={common.dataReviewLang}
        />
      );
    default:
      return (
        <div
          id={common.id}
          className={common.className}
          data-locale={common.dataLocale}
          data-businessunit-id={common.dataBusinessunitId}
          data-style-height="1000px"
          data-style-width="100%"
          data-template-id="539adbd6dec7e10e686debee"
          data-stars="1,2,3,4,5"
          data-theme={common.dataTheme}
          data-review-language={common.dataReviewLang}
        />
      );
  }
};

const Trustpilot = ({ type, product = null, ...rest }) => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';

    document.head.appendChild(script);

    script.onload = function() {
      var checkTrustVar = setInterval(checkTrustWidget, 100);
      function checkTrustWidget() {
        var id = 'trustpilot-' + type;
        var trustWidget = document.getElementById(id);
        if (trustWidget) {
          window.Trustpilot.loadFromElement(trustWidget);
          clearInterval(checkTrustVar);
        }
      }
    };

    return () => {
      document.head.removeChild(script);
    };
  }, [type]);
  const [ref, inView] = useInView({ triggerOnce: true });

  if (inView) {
    return (
      <ReviewsWrapper className="trustpilot-wrapper" {...rest}>
        <TrustpilotWidget type={type} product={product} />
      </ReviewsWrapper>
    );
  } else
    return (
      <ReviewsWrapper ref={ref}>
        <LoadingLine
          heightPx={30}
          widthRem={10}
          className={css`
            margin: 1rem auto;
          `}
        />
      </ReviewsWrapper>
    );
};

export default Trustpilot;
