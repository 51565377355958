const CustomData = ({ children, product, allDataFields = false }) => {
  //RETURN ARRAYS
  let customFields = product && product.customFields;
  let customData = [];
  let customTabs = [];

  /*TODO 
  - MOVE THIS SO THE CUSTOMER COULD SET THESE VALUES IN SITECONTENT
  - SUPPORT MORE CUSTOMEFIELD TYPES (HTML, LIST)
  */

  /*CUSTOM TABS GET CONTENT FROM PAGE & STARTPAGE OR DATA FROM THE CUSTOM FIELD
  tab-data = gets data from custom field itself (string & html or list)
  tab-page = gets data from page id=(int)
  tab-startpage = gets data from startpage id=(int)
  */

  //CUSTOM TABS GET CONTENT FROM PAGE ID SET IN ARRAY
  const tabIds = [
    /*{  
      getPage: true,
      title: false,
      value: 64,
      key: ''
    }*/
  ];

  //CUSTOM DATA GET PAGE ID OR CONTENT FROM THE CERTAIN CUSTOM FIELD TO USE ELSEWERE
  const dataKeys = ['data-youtube', 'product-video', 'Storleksguide'];

  //SEARCH AND DESTROY CUSTOM FIELD & UPDATE FIELDS
  if (product && product.customFields) {
    customFields = [];

    for (let i = 0; i < product.customFields.length; i++) {
      const field = product.customFields[i];

      //CUSTOMTAB WITH JUST DATA
      if (!allDataFields && field.key.search('tab-data') !== -1) {
        customTabs.push(field);
        continue;
      }

      ////CUSTOMTAB IF PAGE OR STARTPAGE CHECK FOR INT
      if (
        field.key.search('tab-startpage') !== -1 ||
        field.key.search('tab-page') !== -1
      ) {
        let custom = {
          ...field
        };

        const id = parseInt(field.stringValue, 10);
        if (!isNaN(id)) {
          custom.pageId = id;
          if (field.key.search('tab-page') !== -1) {
            custom.getPage = true;
          } else if (field.key.search('tab-startpage') !== -1) {
            custom.getStartPage = true;
          }
          customTabs.push(custom);
          continue;
        }
      }

      //CUSTOMDATA
      if (dataKeys.includes(field.key)) {
        customData.push(field);
        continue;
      }

      //ORDINARY FIELD
      customFields.push(field);
    }
  }
  customTabs.push(...tabIds);

  return children(customFields, customTabs, customData);
};

export default CustomData;
