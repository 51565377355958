import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import { useSiteContent } from '../../../Util/useSiteContent';

const TopBarUspWrapper = styled('div')`
  > ul {
    max-width: 700px;
    margin: 0 auto;
    justify-content: space-evenly !important;
    ${theme.only.lg} {
      max-width: 500px;
    }
    > li {
      display: flex;
      align-items: center;
      font-size: ${theme.topBar.usp.fontSize};
      font-weight: ${theme.topBar.usp.fontWeight} !important;
      text-transform: uppercase;
      ${theme.only.xs} {
        font-size: 0.85rem;
      }
      svg {
        margin-right: 5px;
      }
      ${theme.only.xs} {
        &:first-child {
          padding-left: 0 !important;
        }
        &:last-child {
          padding-right: 0 !important;
        }
      }
      ${theme.only.md} {
        &:last-child {
          display: none;
        }
      }
      ${theme.below.sm} {
        &:nth-of-type(3),
        &:nth-of-type(4) {
          display: none;
        }
      }
      ${theme.only.lg} {
        &:last-child {
          display: none;
        }
      }
    }
  }
`;

const TopBarUsp = ({ name }) => {
  let items = useSiteContent([name]);
  let data = useSiteContent(['TopBarUsp']);
  if (items?.length === 0) items = data;

  if (items && items?.show)
    return (
      <TopBarUspWrapper className="top-bar-usp-list">
        {items?.html}
      </TopBarUspWrapper>
    );
  return null;
};

export default TopBarUsp;
