import React from 'react';
import { Link } from 'react-router-dom';

export const ContentLink = ({ to, children }) => {
  if (
    to.search('http') !== -1 ||
    to.search('mailto:') !== -1 ||
    to.search('tel:') !== -1
  )
    return <a href={to}>{children}</a>;

  return <Link to={to}>{children}</Link>;
};
