import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

export const SpecificationsWrapper = styled('div')`
  width: 100%;
  overflow: hidden;
`;

export const FieldWrapper = styled('div')``;

export const FieldTitle = styled('h5')``;

export const FieldData = styled('div')``;

const StringValues = ({ stringValue }) => <div>{stringValue}</div>;
const HtmlValues = ({ htmlValue }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: htmlValue
    }}
  />
);
const BoolValues = ({ boolValue }) => (
  <div>{boolValue ? t('Yes') : t('No')}</div>
);
const ListValues = ({ listValues }) => (
  <ul>
    {listValues.map((listItem, index) => (
      <li key={index}>{listItem}</li>
    ))}
  </ul>
);
const renderFieldData = (field, index) => {
  const type = field.type;
  switch (type) {
    case 'STRING':
      return <StringValues stringValue={field.stringValue} />;
    case 'HTML':
      return <HtmlValues htmlValue={field.htmlValue} />;
    case 'BOOL':
      return <BoolValues boolValue={field.boolValue} />;
    case 'LIST':
      return <ListValues listValues={field.listValues} />;
    default:
      break;
  }
};
const CustomProductSpecifications = ({ fields }) => {
  return fields ? (
    <SpecificationsWrapper className="specifications-wrapper">
      {fields.map((field, index) => (
        <FieldWrapper
          className="field-wrapper"
          key={index}
          data-type={field.type}
        >
          <FieldTitle className="field-title">{field.title}</FieldTitle>
          <FieldData className="field-data">
            {renderFieldData(field, index)}
          </FieldData>
        </FieldWrapper>
      ))}
    </SpecificationsWrapper>
  ) : null;
};
export default CustomProductSpecifications;
