import React from 'react';
import { styled } from 'linaria/react';
import { useSiteContent } from '../../../Util/useSiteContent';
import { LoginWidget } from '../Util/LoginWidget';

const TopBarLinksWrapper = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  > ul {
    > li {
    }
  }
`;

const TopBarLinks = () => {
  const items = useSiteContent(['TopBarLinks']);
  return (
    <TopBarLinksWrapper className="top-bar-links-list">
      {items && items.show && items.html}
      <LoginWidget />
    </TopBarLinksWrapper>
  );
};

export default TopBarLinks;
