import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { styled } from 'linaria/react';
import { GridPrice } from '../Price';
import t from '@jetshop/intl';
import { Favourite } from '../ProductList/Favourite';
import { theme } from '../Theming/Theming';

export const ProductCardInner = styled('div')`
  padding: ${theme.productGrid.product.padding.sm};
  background-color: ${theme.productGrid.product.background};
  border: ${theme.productGrid.product.border};
  position: relative;
  line-height: 1;
  min-width: 0;
  ${theme.above.md} {
    padding: ${theme.productGrid.product.padding.md};
  }
  ${theme.above.xl} {
    padding: ${theme.productGrid.product.padding.lg};
  }
  > a {
    color: inherit;
    display: block;
  }
  > button {
    width: 100%;
  }
`;

export const Details = styled('div')`
  margin: 1rem 0 0.5rem;
  position: relative;
`;

export const DetailsInner = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-end;
  text-align: center;
  > * {
    flex-grow: 0;
    width: 100%;
  }
`;

export const BadgeWrapper = styled('div')`
  color: white;
  text-align: center;
  font-size: 0.8rem;
  ${theme.below.md} {
    img {
      max-width: 50px;
    }
  }
`;

export const Name = styled('h4')`
  margin: 0 0 0.5rem 0 !important;
  padding: 0 0;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.5;
  font-family: ${theme.productGrid.product.name.fontFamily} !important;
  font-weight: ${theme.productGrid.product.name.fontWeight} !important;
  height: 42px;
  overflow: hidden;
  text-transform: uppercase;
`;
export const SubName = styled('p')`
  margin: 0 0 0.2rem 0;
  height: 18px;
  padding: 0 1.5rem;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.9rem;
  color: ${theme.colors.primary};
  font-weight: ${theme.weights.bold};
  > span {
    color: ${theme.colors.primary} !important;
  }
  text-transform: uppercase;
`;
export const ListPrice = styled(GridPrice)`
  [data-flight-price] {
    justify-content: center;
  }
`;

const PackagePrice = styled('div')`
  font-size: 0.8rem;
  margin-top: 5px;
`;

const ProductCardComponent = ({
  product,
  imageAspect,
  imageSizes,
  children,
  showFavorite,
  categoryPath,
  ...rest
}) => {
  //HAS IMAGES
  const hasImages = product.images && product.images.length > 0;

  //STANDARD PRODUCT IMAGE
  let images = product && product.images;

  let badges = [...product.badges];

  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  return (
    <ProductCardInner className="product-card-inner">
      <ProductLink product={product} categoryPath={categoryPath} {...rest}>
        {hasImages ? (
          <Image
            sizes={imageSizes}
            aspect={imageAspect}
            alt={images[0].alt}
            src={images[0].url}
          >
            <BadgeWrapper>
              <Badges badges={product.badges} />
            </BadgeWrapper>
          </Image>
        ) : (
          <Image src={transparentDataImg} />
        )}
      </ProductLink>
      <Details className="product-card-detail">
        {showFavorite && (
          <Favourite
            product={product}
            style={{
              position: 'absolute'
            }}
          />
        )}
        <DetailsInner>
          <header>
            <ProductLink
              product={product}
              categoryPath={categoryPath}
              {...rest}
            >
              <SubName
                className="sub-name"
                dangerouslySetInnerHTML={{ __html: product.subName }}
              ></SubName>
              <Name>{product.name}</Name>
            </ProductLink>
          </header>
          {product.isPackage && (
            <PackagePrice className="package-price-label">
              {t('Package Price')}
            </PackagePrice>
          )}
          <ListPrice
            price={product.price}
            previousPrice={product.previousPrice}
          />
        </DetailsInner>
      </Details>
      {children}
    </ProductCardInner>
  );
};

export function ProductCard({
  product,
  className,
  imageAspect = theme.productGrid.product.imageAspect,
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'div',
  children,
  showFavorite = true,
  showVariants = true,
  ...linkProps
}) {
  const Tag = as;

  //PROPS TO PASS TO PRODUCTCARD COMP
  const props = {
    imageAspect,
    imageSizes,
    children,
    showFavorite,
    ...linkProps
  };

  return (
    <Tag
      className={`product-card ${className}`}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductCardComponent product={product} {...props} />
    </Tag>
  );
}
