import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import ImageComponent from './ImageComponent';
import { RowHeader } from './RowHeader';
import { ScrollBox } from '../../ui/ScrollBox';
import { contentTextElements } from '../ContentText';
import { Above } from '@jetshop/ui/Breakpoints';

const ImageRowWrapper = styled.div`
  .image-row-children {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      width: 100%;
    }
  }
  [data-columns='1'] > div {
    width: 100%;
    ${theme.below.sm} {
      [data-flight-image-container] {
        padding-bottom: 100% !important;
      }
    }
  }
  [data-columns='2'] > div {
    ${theme.above.md} {
      width: 50%;
    }
  }
  [data-columns='3']:not(.mosaic) > div {
    ${theme.above.md} {
      width: 33.33333%;
    }
  }
  [data-columns='4'] > div {
    ${theme.above.sm} {
      width: 50%;
    }
    ${theme.above.lg} {
      width: 25%;
    }
  }

  //MOSAIC LOOK
  [data-columns='3'].mosaic {
    ${theme.above.md} {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      grid-template-areas:
        'small1 big'
        'small2 big';
      > div:nth-of-type(1) {
        grid-area: small1;
      }
      > div:nth-of-type(2) {
        grid-area: small2;
      }
      > div:nth-of-type(3) {
        grid-area: big;
      }

      //IMAGE SIZES
      > div:nth-of-type(1),
      > div:nth-of-type(2) {
        [data-flight-image-container] {
          padding-bottom: 60% !important;
        }
      }
      > div:nth-of-type(3) {
        [data-flight-image-container] {
          padding-bottom: 120% !important;
        }
      }
    }
  }

  //BACKGROUND
  &.primary,
  &.secondary {
    background: ${theme.startPage.row.background.primary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.primary};
    }
    padding: 2rem 5px 2rem 5px;
    ${theme.above.lg} {
      padding: 3rem 5px 2rem 5px;
    }
    .row-header {
      margin: 0 0 1rem;
    }
    .image-row-inner {
      display: flex;
      flex-direction: column;
    }
  }
  &.secondary {
    background: ${theme.startPage.row.background.secondary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.secondary};
    }
  }

  //MARGINS BETWEEN IMAGES
  &.margins {
    .row-header {
      margin-bottom: 1rem;
    }
    .image-row-inner {
      max-width: ${theme.general.maxWidth};
      margin: 0 auto;
      padding: 0 ${theme.general.pagePadding.sm};
      ${theme.below.md} {
        padding: 0;
      }
      .image-row-children {
        padding-top: 2%;
        > div {
          margin-bottom: 1%;
        }
        &[data-columns='2'] > div {
          ${theme.above.md} {
            width: 49.5%;
          }
        }
        &[data-columns='3']:not(.mosaic) > div {
          ${theme.above.md} {
            width: 32.5%;
          }
        }
        &[data-columns='4'] > div {
          ${theme.above.sm} {
            width: 49.5%;
          }
          ${theme.above.lg} {
            width: 24.5%;
          }
        }
        //MOSAIC LOOK
        &[data-columns='3'].mosaic {
          ${theme.above.md} {
            grid-template-columns: 49.5% 49.5%;

            > div:nth-of-type(1) {
              margin-bottom: 2%;
            }

            //IMAGE SIZES
            > div:nth-of-type(1),
            > div:nth-of-type(2) {
              [data-flight-image-container] {
                padding-bottom: 60% !important;
              }
            }
            > div:nth-of-type(3) {
              [data-flight-image-container] {
                padding-bottom: 122% !important;
              }
            }
          }
        }
      }
    }
  }

  //SLIDER WIDTH
  .slider-items {
    .image-component-wrapper {
      margin-right: 2%;
      ${theme.only.sm} {
        width: 55%;
      }
      ${theme.only.md} {
        width: 37%;
      }
      ${theme.only.lg} {
        width: 27%;
      }
      ${theme.above.xl} {
        width: 21%;
      }
    }
  }
`;

const ImageRowChildren = ({ children, aspect, look }) => (
  <Above breakpoint="md">
    {match => {
      return children.map((child, index) => {
        return (
          <ImageComponent
            data={child}
            columns={children.length}
            aspect={match && look === 'mosaic' && index === 2 ? '3:4' : aspect}
            key={index}
            look={look}
          />
        );
      });
    }}
  </Above>
);

const ImageRow = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const aspect = props.imageAspectRatio.value;
  const margins = props.imageMargins.value;
  const background = props.background.value;
  const columns = data.children.length;
  const header = props.header.value;
  const look = props.look.value;

  //SPECIAL IMAGE SIZES FOR MOSAIC LOOK
  const sizes = look === 'mosaic' ? [1, 1, 1, 1 / 2] : null;

  let cssStyles = margins === 'yes' ? 'margins' : '';
  cssStyles += ' ' + background;
  cssStyles += header ? ' has-header' : '';

  if (data && data.children)
    return (
      <ImageRowWrapper
        data-columns={columns}
        data-index={index}
        className={`image-row ${cssStyles}`}
      >
        <div className="image-row-inner">
          <RowHeader props={props} />
          {columns > 4 && look === 'standard' ? (
            <ScrollBox>
              <ImageRowChildren
                children={data.children}
                aspect={aspect}
                sizes={sizes}
                look={look}
              />
            </ScrollBox>
          ) : (
            <div
              className={`image-row-children ${look}`}
              data-columns={columns}
            >
              <ImageRowChildren
                children={data.children}
                aspect={aspect}
                sizes={sizes}
                look={look}
              />
            </div>
          )}
        </div>
      </ImageRowWrapper>
    );
  else return null;
};

export default ImageRow;
