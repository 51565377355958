import { css } from 'linaria';

import bodyLight from './helvetica-neue-light.woff2';
import bodyBold from './helvetica-neue-bold.woff2';
import secondary from './baskerville.woff2';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      @font-face {
        font-family: 'helvetica';
        src: url(${bodyLight}) format('woff2');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'helvetica';
        src: url(${bodyBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'baskerville';
        src: url(${secondary}) format('woff2');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
      }
    }
  `;
}
