import React from 'react';
import { styled } from 'linaria/react';
import { InlineImage } from '../../ui/InlineImage';
import Image from '@jetshop/ui/Image/Image';
import { convertPropsToObject } from '../ContentRender';
import { theme } from '../../Theming/Theming';
import { ContentHtml } from '../ContentText';
import { ContentLink } from '../ContentLink';

const ImageComponentWrapper = styled.div`
  .image-component-inner {
    position: relative;
    &.box-right,
    &.box-left {
      display: flex;
      flex-wrap: wrap;
      > * {
        width: 100%;
        ${theme.above.md} {
          &.image-wrapper {
            width: 60%;
          }
          &.image-text {
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    &.box-left {
      ${theme.above.md} {
        .image-wrapper {
          order: 1;
        }
      }
    }
  }
`;

const ImageWrapper = styled('div')`
  display: block;
  overflow: hidden;
`;
const TextContent = styled(ContentHtml)`
  position: absolute;
  z-index: 2;
  padding: 5%;

  > *:last-child {
    margin-bottom: 0 !important;
  }

  //POSIITONS
  &.top-left,
  &.top-center,
  &.top-right {
    top: 0;
  }
  &.middle-left,
  &.middle-center,
  &.middle-right {
    top: 50%;
  }
  &.bottom-left,
  &.bottom-center,
  &.bottom-right {
    bottom: 0;
  }

  &.top-left,
  &.middle-left,
  &.bottom-left {
    left: 0;
  }
  &.top-center,
  &.middle-center,
  &.bottom-center {
    text-align: center;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &.top-right,
  &.middle-right,
  &.bottom-right {
    text-align: right;
    left: 30%;
    right: 0;
  }

  &.middle-left,
  &.middle-right {
    transform: translateY(-50%);
  }

  &.middle-center {
    transform: translateX(-50%) translateY(-50%);
  }

  &.under,
  &.box-left,
  &.box-right {
    text-align: center;
    position: static;
    &.light {
      background: ${theme.colors.backgroundDark};
    }
  }

  &.under {
    padding-bottom: 10%;
    text-align: left;
  }

  &.box-left,
  &.box-right {
    background: ${theme.colors.backgroundLight};
  }
`;

function getSizes(columns) {
  if (columns === 2) return [1, 1, 1 / 2, 1 / 2];
  else if (columns === 3) return [1, 1, 1 / 2, 1 / 3];
  else if (columns === 4) return [1, 1, 1 / 2, 1 / 4];
  return [1, 1, 1, 1];
}

const ImageComponentInner = ({ props, imageSizes, aspect, crop }) => {
  const imageUrl = props.imageUrl.value;
  const text = props.text.value;
  const textPosition = props.textPosition.value;
  const textColor = props.textColor && props.textColor.value;
  return (
    <div className={`image-component-inner ${textPosition}`}>
      {imageUrl && (
        <ImageWrapper className={`image-wrapper`}>
          {aspect === 'inline' ? (
            <InlineImage sizes={imageSizes} src={imageUrl} alt={imageUrl} />
          ) : (
            <Image
              className="image-holder"
              aspect={aspect}
              sizes={imageSizes}
              src={imageUrl}
              alt={imageUrl}
              crop={crop || true}
              cover
            />
          )}
        </ImageWrapper>
      )}
      <TextContent
        className={`image-text ${textPosition} ${textColor}`}
        content={text}
      />
    </div>
  );
};

const ImageComponent = ({ data, columns, aspect = '1:1', sizes, crop }) => {
  const props = convertPropsToObject(data);
  const link = props.link.value;
  const imageSizes = sizes || getSizes(columns);
  return (
    <ImageComponentWrapper className={`image-component-wrapper`}>
      {link ? (
        <ContentLink to={link}>
          <ImageComponentInner
            props={props}
            imageSizes={imageSizes}
            aspect={aspect}
            crop={crop}
          />
        </ContentLink>
      ) : (
        <ImageComponentInner
          props={props}
          imageSizes={imageSizes}
          aspect={aspect}
          crop={crop}
        />
      )}
    </ImageComponentWrapper>
  );
};

export default ImageComponent;
