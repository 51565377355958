import PropTypes from 'prop-types';
import React from 'react';
import StockOrb from './StockOrb';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

const StockStatusWrapper = styled('p')`
  margin: 0 0 0 0;
  display: flex;
  align-items: center;
`;

const StockStatusIndicator = ({ status, text }) => {
  return (
    <StockStatusWrapper className="stock-status-wrapper">
      <StockOrb status={status} />
      <span>{stockStatusMask(text)}</span>
    </StockStatusWrapper>
  );
};

export const stockStatusMask = status => {
  const value = parseInt(status);
  if (value === 0) return t('Out of stock');
  if (value < 3) return t('A few left');
  if (value > 2) return t('In stock');
  return status;
};

StockStatusIndicator.propTypes = {
  status: PropTypes.oneOf(['inStock', 'outOfStock', 'notifyWhenBack']),
  text: PropTypes.string
};

export default StockStatusIndicator;
