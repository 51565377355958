import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { useSiteContent } from '../../Util/useSiteContent';
import Image from '@jetshop/ui/Image';

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  ${theme.below.sm} {
    flex-direction: column;
  }
  > div {
    width: 100%;
    padding: 2rem 1rem;
    ${theme.only.md} {
      width: 40%;
    }
    ${theme.above.lg} {
      width: 20%;
      padding: 3rem 1rem;
    }
    > h3 {
      margin-bottom: 1em !important;
    }
    > ul {
      > li {
        line-height: 2em;
      }
    }
    p {
      line-height: 1.7em;
      margin-bottom: 1rem;
    }
  }
`;

const LogoWrapper = styled.div`
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
`;

const FooterContent = () => {
  const item = useSiteContent(['Footer']);

  if (!item) return null;

  return (
    <>
      <FooterContentWrapper>{item.html}</FooterContentWrapper>
      <LogoWrapper>
        <Image
          critical={true}
          aspect={'100:106'}
          crop={false}
          src={item.image}
          sizes={[500]}
          quality={80}
        />
      </LogoWrapper>
    </>
  );
};

export default FooterContent;
